import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Helmet from '../components/Helmet';
import Layout from '../components/Layout';
import WordPressContent from '../components/WordPressContent';
import Pagination from '../components/Pagination';
import Teaser from '../components/Teaser';
import DebugGrid from '../components/DebugGrid';
import { sanitizeTeaserTitle, sanitizeTeaserText } from '../utils/wordpressHelper';
import { addTrailingSlash } from '../utils/helper';

import css from './Categories.module.css';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    allWordpressPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          excerpt: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    name: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    pagePathHome: PropTypes.string.isRequired,
    pagePathPrefix: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

const defaultProps = {

};

function Categories({
  location: {
    pathname,
  },
  data: {
    site: {
      siteMetadata: {
        siteUrl,
      },
    },
    allWordpressPost: {
      nodes,
    },
  },
  pageContext: {
    currentPage,
    numPages,
    pagePathHome,
    pagePathPrefix,
    name,
    description,
  },
}) {
  const schemata = [
    {
      '@type': 'ItemList',
      itemListElement: nodes.map(({ path }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: addTrailingSlash(`${siteUrl}${path}`),
      })),
    },
  ];

  return (
    <>
      <Helmet
        path={pathname}
        title={currentPage === 1 ? name : `${name}, Seite ${currentPage}`}
        topLevelSchemata={schemata}
        next={currentPage < numPages - 1 ? `${pagePathPrefix}${currentPage + 1}/` : null}
        prev={currentPage > 1 ? `${pagePathPrefix}${currentPage - 1}/` : null}
      />
      <Layout>
        <div className="ft-grid ft-flex ft-flex--col ft-gap-margin ft-gap-margin--col">
          <h1>{`Thema: ${name}`}</h1>
          {description && (
            <p className={css.categories__description}>
              <WordPressContent content={description} />
            </p>
          )}
        </div>
        {nodes.map(({
          title, excerpt, slug, path, categories,
        }) => (
          <Teaser
            key={slug}
            url={path}
            title={sanitizeTeaserTitle(title)}
            text={sanitizeTeaserText(excerpt)}
            categories={
              categories && categories.length > 0
                ? categories.map(
                  ({ name: catName, path: catPath }) => ({ label: catName, url: catPath }),
                )
                : []
            }
          />
        ))}
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          pagePathHome={pagePathHome}
          pagePathPrefix={pagePathPrefix}
          backLabel="Zurück zur ersten Seite"
        />
      </Layout>
      <DebugGrid />
    </>
  );
}

export const query = graphql`
  query CategoriesQuery($descendantsAndSelfCategoryIds: [Int!], $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { categories: { elemMatch: { wordpress_id: { in: $descendantsAndSelfCategoryIds } } } }
    ) {
      nodes {
        title
        excerpt
        slug
        path
        categories {
          path
          name
        }
      }
    }
  }
`;

Categories.propTypes = propTypes;
Categories.defaultProps = defaultProps;

export default Categories;
